import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Checkbox } from '@opengov/component-library/capital';
import { FigmaEmbed, PatternExample, UsageBlock } from '../../../components';
import LayoutComponents from '../../../components/capital/Layouts/LayoutComponents';
export const _frontmatter = {
  "title": "Checkbox",
  "componentId": "checkbox",
  "description": "Checkboxes are used to gather boolean data from a user.",
  "activeTab": "components"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = LayoutComponents;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><strong parentName="p">{`Documentation in progress----------`}</strong></p>
    <h2><strong parentName="h2">{`Variants`}</strong></h2>
    <PatternExample example={<Checkbox label="Example" mdxType="Checkbox" />} title="Checkbox" description={"Create a group of checkboxes when users can select any combination of options."} mdxType="PatternExample" />
    <h2><strong parentName="h2">{`Configurations`}</strong></h2>
    <h4><strong parentName="h4">{`Small`}</strong></h4>
    <PatternExample example={<Checkbox.Small label="Example" />} title="Small Checkbox" description={"Create a group of checkboxes when users can select any combination of options."} mdxType="PatternExample" />
    <h4><strong parentName="h4">{`Color`}</strong></h4>
    <PatternExample example={<Checkbox color="#ff0000" label="Example" mdxType="Checkbox" />} title="Color Checkbox" description={"Create a group of checkboxes when users can select any combination of options."} mdxType="PatternExample" />
    <h3>{`Usage`}</h3>
    <UsageBlock mdxType="UsageBlock">
  <UsageBlock.Correct>
    <p>...</p>
  </UsageBlock.Correct>
  <UsageBlock.Incorrect>
    <p>...</p>
  </UsageBlock.Incorrect>
    </UsageBlock>
    <h2>{`Layout`}</h2>
    <PatternExample example={"..."} title="..." description={"Description about layout structure"} mdxType="PatternExample" />
    <h2>{`Content`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      